<div class="container mt-2">
    <div class="">
        <div class="row">
            <div class="col-md-3">
                <i class="bi-emoji-smile-fill big-icon text-teal"></i>
            </div>

            <div class="col-md-9 align-content-center">
                <h2 class="featurette-heading"
                    i18n>
                    Underdevelopment!
                </h2>

                <div class="row">
                    <div class="col">
                        <p i8n>
                            Please check back soon for more information.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
