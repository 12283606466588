import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { NavMenuComponent } from './nav-menu/component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    standalone: true,
    imports: [
        CommonModule,
        NavMenuComponent,
        RouterLink,
        RouterOutlet,
    ]
})
export class AppComponent implements OnInit {
    public year: number | null = null;

    ngOnInit() {
        this.year = new Date().getFullYear();
    }
}
