import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'nav-menu',
    templateUrl: './component.html',
    standalone: true,
    imports: [
        CommonModule,
        RouterLink,
    ]
})
export class NavMenuComponent implements OnInit {
    isExpanded: boolean = false;
    storedTheme: string | null = localStorage.getItem('theme') ? localStorage.getItem('theme') : 'auto';

    collapse() {
        this.isExpanded = false;
    }

    getPreferredTheme() {
        if (this.storedTheme)
            return this.storedTheme;
        else
            return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    }

    ngOnInit() {
        this.setTheme(this.getPreferredTheme());
    }

    setTheme(theme: string) {
        if (theme === 'auto') {
            document.documentElement.setAttribute('data-bs-theme', (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'));
            localStorage.setItem('theme', theme);
            this.storedTheme = theme;
        } else {
            document.documentElement.setAttribute('data-bs-theme', theme);
            localStorage.setItem('theme', theme);
            this.storedTheme = theme;
        }

        document.documentElement.classList.remove('d-none');
    }

    toggle() {
        this.isExpanded = !this.isExpanded;
    }
}
