import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { UrlSerializer, provideRouter, withComponentInputBinding, withInMemoryScrolling } from '@angular/router';
import { ROUTES } from './app.routes';
import { LowerCaseUrlSerializer } from './lower-case.serializer';

export const appConfig: ApplicationConfig = {
    providers: [
        {
            provide: UrlSerializer,
            useClass: LowerCaseUrlSerializer
        },
        provideRouter(ROUTES, withComponentInputBinding(), withInMemoryScrolling({
            scrollPositionRestoration: 'top'
        })),
        provideZoneChangeDetection({ eventCoalescing: true })
    ]
};
