<body>
    <nav-menu />

    <router-outlet />

    <div class="container-fluid border-top">
        <div class="navbar navbar-bottom nav-fill">
        </div>

        <div class="row mb-2">
            <div class="col text-center">
                <small class="text-body-secondary">&copy; {{year}} <span i18n>eduCACE, All rights reserved.</span></small>
            </div>
        </div>
    </div>
</body>
